<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" style="opacity: 1">
        <div data-testid="hero-image-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
          <img style="width: 100%; height: 100%;" :src="require('@/assets/images/accommodations/b1.png')" alt="" />
        </div>
        <div
          class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]"
        >
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1"
          >
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-700 md:text-iblack-700 dark:text-iblack-300 max-w-[500px]">
                Accommodations and Flexible Living
              </h1>
              <p class="text-lg text-iblack-600 md:text-iblack-700 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">
                We believe our network of urban residential communities<br class="hidden md:inline" />
                amplifies the convenience, connection, and comfort of home.
              </p>
            </div>
          </div>
        </div>
        <div class="main-grid">
          <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
            <div
              role="separator"
              aria-orientation="horizontal"
              class="w-full h-full block"
              style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <section class="">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      style="  transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/accommodations/b2.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          style="  transform: translateY(20px) translateZ(0px)"
                        >
                          Overview
                        </h1>
                        <p
                          class="text-iblack-600 mt-2.5"
                          style="  transform: translateY(20px) translateZ(0px)"
                        >
                          The rise in location-independent careers and alternative accommodations has birthed a new
                          generation of working professionals who want to live and travel on their terms. As a result,
                          we expect the days of the 12-month lease are numbered. The future of rental housing is
                          flexibility.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://www.sentral.com/"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/accommodations/b3.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            Sentral is Redefining Home
                          </h1>
                          <p
                            class="text-iblack-600 mt-2.5"
                            style="  transform: translateY(20px) translateZ(0px)"
                          >
                            In partnership with operating platform Sentral, a leading prop-tech innovator, we are
                            striving to build the world’s largest flexible living community. Offering both
                            designer-furnished and unfurnished homes in some of the nation’s most-coveted cities,
                            Sentral enables residents to live in amenity-rich buildings for any period of time—from a
                            night to a month to multiple years.
                          </p>
                          <div class="mt-22" style="  transform: translateY(20px) translateZ(0px)">
                            <span
                              class="inline-flex items-center button-target transition-opacity duration-400 opacity-100 hover:opacity-80"
                              ><span
                                >
                                <img style="width: 34px; height: 34px;" :src="require('@/assets/images/accommodations/right-circle.png')" alt="" />
                              </span>
                              <span
                                class="text-iblack-700 dark:text-iwhite-100 md:text-iblack-700 dark:text-iwhite-100 ml-3"
                              >
                                Visit Sentral
                              </span></span
                            >
                          </div>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="flex items-center main-grid--in-grid justify-center">
          <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
            <div class="col-span-6">
              <div
                class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-li:marker:text-iblack-600 prose-a:dark:text-iblack-300 prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 md:text-center"
                style="  transform: translateY(20px) translateZ(0px)"
              >
                <div class="text-center">
                  <h1 class="mb-[30px] font-medium">
                    “I've stayed at three Sentrals so far. Each location<br class="hidden md:inline" />
                    is a unique experience and is always amazing.”
                  </h1>
                  <p class="m-0">Jan O, Sentral Guest.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-2 relative">
                <div>
                  <section class="">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      style="  transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/accommodations/b4.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-6">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          style="  transform: translateY(20px) translateZ(0px)"
                        >
                          Live
                        </h1>
                        <p
                          class="text-iblack-600 mt-2.5"
                          style="  transform: translateY(20px) translateZ(0px)"
                        >
                          Live or stay in Sentral apartments for any length of time. Amenity-rich communities in prime
                          walkable neighborhoods put you at the center of vibrant city life.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-7 col-start-10 relative">
                <div>
                  <section class="">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      style="  transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/accommodations/b5.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-6">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          style="  transform: translateY(20px) translateZ(0px)"
                        >
                          Travel
                        </h1>
                        <p
                          class="text-iblack-600 mt-2.5"
                          style="  transform: translateY(20px) translateZ(0px)"
                        >
                          Make yourself at home in a designer-furnished turnkey apartment, or style an unfurnished
                          apartment in cities across the country.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="col-span-7 col-start-18 relative">
                <div>
                  <section class="">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      style="  transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/accommodations/b6.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-6">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          style="  transform: translateY(20px) translateZ(0px)"
                        >
                          Work
                        </h1>
                        <p
                          class="text-iblack-600 mt-2.5"
                          style="  transform: translateY(20px) translateZ(0px)"
                        >
                          Clever work-from-home upgrades, fast WiFi, and unique community spaces allow you to stay
                          focused, be creative, and collaborate.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155);   transform: scale(0.25) translateZ(0px)"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div class="flex items-center main-grid--in-grid justify-left">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-5">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-li:marker:text-iblack-600 prose-a:dark:text-iblack-300 prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 md:text-left"
                        style="  transform: translateY(20px) translateZ(0px)"
                      >
                        <h3>Property Photos</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div data-testid="gallery-wrapper" class="main-grid--in-grid overflow-hidden">
          <div class="relative overflow-hidden flex justify-center items-center h-[500px] lg:h-[650px]">
            <div
              class="absolute rounded w-full h-full top-0 left-0 will-change-[opacity]"
              data-testid="gallery-image-7"
              style="opacity: 1"
            >
              <img style="width: 100%; height: 100%;" :src="require('@/assets/images/accommodations/b7.png')" alt="" />
            </div>
            <button
              data-testid="gallery-prev"
              aria-label="Previous slide"
              class="absolute rounded z-10 w-1/3 h-full left-0 bottom-0 cursor-pointer bg-gradient-to-r from-gray-700 via-gray-900 transition-opacity duration-300 opacity-0 hover:opacity-30 focus:opacity-30"
            ></button
            ><button
              data-testid="gallery-next"
              aria-label="Next slide"
              class="absolute rounded z-10 w-1/3 h-full right-0 bottom-0 cursor-pointer bg-gradient-to-l from-gray-700 via-gray-900 transition-opacity duration-300 opacity-0 hover:opacity-30 focus:opacity-30"
            ></button>
          </div>
          <div class="relative flex justify-between pt-[20px]">
            <p class="text-iblack-600 dark:text-iblack-400 text-sm" style="opacity: 1">Atlanta Pool</p>
            
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div
          class="relative transition-opacity duration-300 overflow-hidden col-start-1 col-span-full"
          style="transform: translateY(40px) translateZ(0px)"
        >
          
          <div
            data-testid="tout-video-bg"
            class="col-start-1 col-span-full md:h-[100%] w-[100vw] top-0 left-0 z-[-1] overflow-hidden"
          >
            <div class="relative w-full h-full overflow-hidden max-h-[80vh] min-h-[100%] w-full">
              <div autoplay="" style="width: 100%; height: 100%">
                <img style="width: 100%; height: 100%;" :src="require('@/assets/images/accommodations/b8.png')" alt="" />
              </div>
            </div>
          </div>
          <div
            class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:absolute md:top-0 md:left-0 md:w-full"
          >
            <div
              class="col-end-flex flex flex-col z-1 col-start-2 col-span-15 md:col-start-2 lg:col-start-2 justify-start mt-[30px] md:mt-[40px] mb-[30px] md:my-0"
            >
              <div class="relative flex flex-col justify-between md:h-full my-0">
                <div>
                  <h1 class="text-2xl text-iblack-700 dark:text-iblack-300 md:dark:text-iblack-700">
                    Owned Property Locations
                  </h1>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="flex items-center main-grid--in-grid justify-left">
          <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
            <div class="col-span-6">
              <div
                class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-li:marker:text-iblack-600 prose-a:dark:text-iblack-300 prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 md:text-left"
                style="  transform: translateY(20px) translateZ(0px)"
              >
                <p></p>
                <p class="text-sm">ZEN CAPITAL Real Assets-owned property locations managed by Sentral.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
